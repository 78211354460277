import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import { IThemed } from '_Styles/types';

export const ButtonX = styled.div<
  IThemed & { buttonV2?: boolean } & { size?: string }
>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 600;

  .plx-button {
    width: 30rem;
  }
  button.MuiButton-containedPrimary {
    background-color: ${({ buttonV2 }) => (buttonV2 ? '#fff' : '#4f007d')};
    border-radius: 50px;
    height: ${({ size }) => (size === 'small' ? '35px' : '40px')};
    margin: 0;
    color: ${({ buttonV2 }) => (buttonV2 ? '#000' : '')};
    border: ${({ buttonV2 }) => (buttonV2 ? '1px solid #e5e5e5' : '')};
  }
  .hideStepOne {
    display: hidden;
  }
  button.MuiButton-containedPrimary.Mui-disabled {
    background-color: #0000001f;
  }
  span {
    font-size: 1.5rem;
    font-weight: ${({ size }) => (size === 'small' ? '500' : '600')};
    padding: ${({ size }) => (size === 'small' ? '1rem' : '')};
  }
  .MuiButtonBase-root:hover {
    background: ${({ buttonV2 }) => (buttonV2 ? '#4f007d' : '#ca85d7')};
    color: ${({ buttonV2 }) => (buttonV2 ? '#fff' : '')};
  }
`;
export const NewButtonX = styled.div<
  IThemed & { buttonV2?: boolean } & { size?: string } & {
    validation?: boolean;
  }
>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: 'Quicksand';
  font-style: normal;
  font-size: 14px;
  line-height: 18px;

  button.MuiButton-containedPrimary {
    background-color: #f5f5f5;
    border-radius: 6px;
    height: ${({ size }) => (size === 'small' ? '35px' : '40px')};
    margin-left: ${({ buttonV2 }) => (buttonV2 ? '10px' : '')};
    color: ${({ buttonV2 }) => (buttonV2 ? '#fff' : '#000')};
    background: ${({ buttonV2 }) => (buttonV2 ? '#000' : '')};
  }
  button.MuiButton-root {
    padding: ${({ validation }) =>
      validation ? '1.1rem 1.6rem' : '1.1rem 3.2rem'};
  }
  .hideStepOne {
    display: hidden;
  }
  button.MuiButton-containedPrimary.Mui-disabled {
    background-color: #0000001f;
  }
  span {
    font-size: 1.5rem;
    font-weight: ${({ validation }) => (validation ? 500 : 600)};
    padding: 0;
  }
  .MuiButtonBase-root:hover {
    background: ${({ buttonV2 }) => (buttonV2 ? '' : '#000')};
    color: ${({ buttonV2 }) => (buttonV2 ? '' : '#fff')};
  }
`;
export const ButtonXAnalytics = styled.div<IThemed>`
  // border-color: #000;
`;

export const NotificationBadge = styled.div<IThemed>`
  position: absolute;
  right: -1rem;
  top: -20%;
  border-radius: 50%;
  background-color: #ff0000;
  color: #f4f4f4;
  height: 2.2rem;
  width: 2.2rem;
  display: flex;
  justify-content: center;
  font-weight: bold;
  align-items: center;
`;

export const StyledButton = styled(Button)<{ fullWidth?: boolean }>`
  /* padding: 1.1rem 3.2rem; */
  text-transform: capitalize;
  position: relative;
  white-space: nowrap;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
`;
export const StyledButtonAnalytics = styled(Button)`
  && {
    border: 1px solid #dbdbdb;
    padding: 0;
    font-weight: 100;
    height: 2.5rem;
    font-size: 1.2rem;
    margin-left: 0;
  }
`;

export const StyledEditIconButton = styled(Button)`
  && {
    padding: 0;
    min-width: 20px;
    height: 20px;
    margin-right: 10px;
  }
`;
