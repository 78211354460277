import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ICommonProps } from '_Types/props';
import XemotoFullLogo from './../../../src/xemotoFullLogo.svg';
import { Divider } from '@material-ui/core';
// @ts-ignore
import videoSource from './finfluencerPromo.mp4';

const Main = styled.div`
  display: flex;
  // flex-direction: column;
  width: 100%;
  z-index: 1;
  font-weight: 700;
  hr {
    height: 75%;
    display: flex;
    align-self: center;
    background-color: #e7e7e7;
  }
`;
const TextArea = styled.div`
  width: 100%;
  background: #fff;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 0 5rem;

  img {
    width: 20rem;
    padding-bottom: 3rem;
  }
  .title {
    font-size: 2.5rem;
    padding-top: 1rem;
  }
  .subtitle {
    width: 90%;
    font-size: 1.5rem;
    line-height: 2.2rem;
    color: #231f20;
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
`;
const XemotoLogo = styled.img`
  // filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
`;
const Disclosure = styled.div`
  margin-top: 5rem;
  a {
    text-decoration: none;
    font-weight: normal;
    font-size: 1rem;
  }
`;
interface IProps extends ICommonProps {
  title?: string;
  subtitle: string;
  subtitle2?: string;
  isClient?: boolean;
  distributionPartner?: boolean;
}

export const PlxSideContainer: FC<IProps> = ({
  className,
  title,
  subtitle,
  isClient,
  distributionPartner,
}) => {
  return (
    <Main className={className}>
      <TextArea>
        <XemotoLogo src={XemotoFullLogo} />
        {title && <div className={'title'}>{title}</div>}
        <div className={'subtitle'}>{subtitle}</div>

        {isClient && (
          <Disclosure>
            <a href="/auth/disclosure" target="_blank">
              Disclosure
            </a>
          </Disclosure>
        )}
      </TextArea>
      {distributionPartner && (
        <TextArea>
          <div style={{ width: '240px' }}>
            <video width="100%" controls autoPlay muted>
              <source src={videoSource} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </TextArea>
      )}

      <Divider orientation="vertical" flexItem />
    </Main>
  );
};
