import styled from '@emotion/styled';

export const ContactDetailsWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 50rem;
  text-align: center;
  line-height: 2.75rem;
  margin: auto;
`;
export const Header = styled.h1`
  font-size: 3rem;
  padding: 3rem;
`;
export const SubHeader = styled.h2`
  font-size: 2rem;
  padding-bottom: 3rem;
  font-weight: 300;
`;
export const Contact = styled.h3`
  font-size: 1.75rem;
  font-weight: 300;

  span {
    font-size: 1rem;
  }
  a {
    text-decoration: none;
    color: #5a1161;
  }
`;
export const SocialLinks = styled.div`
  margin: 4rem 0 0 0;
  ul {
    display: flex;
    flex-direction: row;

    li {
      width: 100px;
      border: 1px solid #a9b3bb;
      margin-left: -1px;
      padding: 0.5rem 0;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:first-of-type {
        border-radius: 5px 0 0 5px;
        &:hover {
          background: #0077b5;

          path {
            stroke: #fff;
          }
        }
      }

      &:last-of-type {
        border-radius: 0 5px 5px 0;

        &:hover {
          background: #00acee;

          path {
            stroke: #fff;
          }
        }
      }
      svg {
        height: 1.75rem;
        max-width: 1.75rem;
      }
    }
  }
`;
export const SecondLi = styled.li`
  &:hover {
    background: #3b5998;

    path {
      stroke: #fff;
    }
  }
`;

// export const Faqs = styled.a`
//   border: 1px solid #a9b3bb;
//   border-radius: 5px;
//   margin-bottom: 4rem;
//   padding: 0.5rem 5rem;
//   text-decoration: none;
//   color: #000;
// `;
