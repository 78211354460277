import React, { FC, useContext } from 'react';
import { ReactComponent as Facebook } from './../../../../../../_Styles/images/_Icons/ChannelTypes/Facebook_1.svg';
import { ReactComponent as Twitter } from './../../../../../../_Styles/images/_Icons/ChannelTypes/Twitter_1.svg';
import { ReactComponent as LinkedIn } from './../../../../../../_Styles/images/_Icons/ChannelTypes/Linked_1.svg';
import { TabPageHeader } from '../../utils';
import { FinfluencerContactUsForm } from './FinfluencerContactUsForm';
import { Body } from '../FinfluencerDashboard/DistributionPartnerDashboard';
import { NavigationContext } from '_Routes/Home/NavigationContext';
import {
  ContactDetailsWrap,
  Header,
  SubHeader,
  Contact,
  SocialLinks,
  SecondLi,
  // Faqs,
} from './FinfluencerContactUsStyle';
import { useTranslation } from 'react-i18next';

export const FinfluencerContactUs: FC = () => {
  const { t } = useTranslation();
  const { navBarExpanded } = useContext(NavigationContext);

  return (
    <Body isExpanded={navBarExpanded}>
      <div>
        <TabPageHeader name={'Contact Us'} />
      </div>

      <ContactDetailsWrap>
        <Header>{t('contactUs.gettingInTouch')}</Header>
        <SubHeader>{t('contactUs.happyToChat')}</SubHeader>
        {/* <Faqs
            href="https://www.untappedlab.com/?page_id=5250"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('contactUs.faqs')}
          </Faqs> */}

        <Contact>
          {t('contactUs.phoneNo')}
          {/* <span>{t('contactUs.tollFree')}</span> */}
        </Contact>
        <Contact>
          <a href="mailto:info@xemotomedia.com">{t('contactUs.email')}</a>
        </Contact>
        <Contact>
          <a href="https://xemotomedia.com">{t('contactUs.website')}</a>
        </Contact>

        <SocialLinks>
          <ul>
            <li>
              <a
                href="https://www.linkedin.com/company/xemoto-media"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedIn />
              </a>
            </li>
            <SecondLi>
              <a
                href="https://www.facebook.com/Xemoto.Media"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Facebook />
              </a>
            </SecondLi>
            <li>
              <a
                href="https://twitter.com/XemotoMedia"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Twitter />
              </a>
            </li>
          </ul>
        </SocialLinks>
      </ContactDetailsWrap>
      <FinfluencerContactUsForm />
    </Body>
  );
};
