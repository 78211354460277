import React, { FC, useRef } from 'react';
import XemotoLogoLargeNew from '_Styles/images/XemotoLogoLargeNew.png';
import styled from '@emotion/styled';
import { IThemed } from '_Styles/types';
import { Trans } from 'react-i18next';
import { Input } from '@material-ui/core';
import { Document, Image, Text, Page } from '@react-pdf/renderer';
import SignaturePad from 'react-signature-canvas';
import { HorizontalDivider } from '../FinfluencerWallet/utils';
import signByCeo from '_Styles/images/signByCEO.png';
import {
  BodyWrap,
  ButtonWrap,
  getCurrentDate,
  OL,
  ParagraphWrap,
  SignatureWrap,
  StyledPopup,
  styles,
  UL,
  IdentifierWrap,
  ErrorWrap,
} from './utils';
import GetAppIcon from '@material-ui/icons/GetApp';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButton';

const SpectrumLogoContainer = styled.div<IThemed>`
  display: flex;
  flex-direction: row;
`;

const UntappedLabImage = styled.img<IThemed>`
  height: 2.6rem;
  cursor: pointer;
`;

interface IProps {
  formik: any;
  pdf?: boolean;
}

export const AllPages: FC<IProps> = ({ formik, pdf }) => {
  const sigCanvas: any = useRef({});
  const clear = () => sigCanvas.current.clear();
  const save = () => {
    if (!sigCanvas.current.isEmpty()) {
      formik.setFieldValue(
        'signUrl',
        sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
      );
    }
  };
  return (
    <Document>
      <Page size="A4" style={pdf ? styles.bodyPdf : styles.bodyWeb}>
        <BodyWrap>
          <OL>
            <SpectrumLogoContainer>
              <UntappedLabImage
                src={XemotoLogoLargeNew}
                style={{
                  height: '8rem',
                  marginLeft: '-23px',
                }}
              />
            </SpectrumLogoContainer>
            <Text style={pdf ? styles.text2 : styles.text3}>
              <p>
                <Trans i18nKey="payment.finfluencerEngagementLetter.address" />
              </p>
            </Text>
            <Text style={pdf ? styles.text2 : styles.text3}>
              <p>
                <Trans i18nKey="payment.finfluencerEngagementLetter.address2" />
              </p>
            </Text>
            <Text style={pdf ? styles.text2 : styles.text3}>
              <p>
                <Trans i18nKey="payment.finfluencerEngagementLetter.address3" />
              </p>
            </Text>
            <Text style={pdf ? styles.text2 : styles.text3}>
              <p>
                <Trans i18nKey="payment.finfluencerEngagementLetter.address4" />
              </p>
            </Text>
            <Text style={pdf ? styles.text : styles.text2}>
              <p>
                <Trans i18nKey="payment.finfluencerEngagementLetter.contactEmail" />
              </p>
            </Text>
            <Text style={pdf ? styles.text : styles.text2}>
              <Trans i18nKey="payment.finfluencerEngagementLetter.date" />
              <span>{getCurrentDate()}</span>
            </Text>
            <ParagraphWrap>
              {!pdf ? (
                <Text style={styles.text}>
                  <p>
                    <Trans i18nKey="payment.finfluencerEngagementLetter.name" />
                  </p>
                  <Input
                    name="company"
                    placeholder="Company/Full Name"
                    style={{
                      width: '200px',
                      margin: '0 1rem',
                    }}
                    onChange={formik.handleChange}
                    value={formik.values.company}
                    data-test-id="main-company-name-field"
                  />
                </Text>
              ) : (
                <Text style={styles.textForwarded}>
                  <span>
                    <p>{formik.values.company}</p>
                  </span>
                </Text>
              )}
            </ParagraphWrap>
            {!pdf ? (
              <>
                <li>
                  <span style={styles.formSpan}>
                    <Trans i18nKey="payment.finfluencerEngagementLetter.influencerAddress" />
                  </span>
                </li>
                <li
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '1rem',
                  }}
                >
                  <Input
                    name="streetAddress"
                    placeholder="Street Address"
                    style={{
                      width: '200px',
                    }}
                    onChange={formik.handleChange}
                    value={formik.values.streetAddress}
                    data-test-id="street-adress-field"
                  />
                  <span>
                    <Input
                      name="city"
                      placeholder="City"
                      style={{
                        width: '150px',
                      }}
                      onChange={formik.handleChange}
                      value={formik.values.city}
                      data-test-id="city-address-field"
                    />
                  </span>
                </li>

                <li
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '1rem',
                  }}
                >
                  <Input
                    name="provinceState"
                    placeholder="Province/State"
                    style={{
                      width: '150px',
                    }}
                    onChange={formik.handleChange}
                    value={formik.values.provinceState}
                    data-test-id="province-address-field"
                  />

                  <span>
                    <Input
                      name="postalZip"
                      placeholder="Postal/Zip code"
                      style={{
                        width: '150px',
                      }}
                      onChange={formik.handleChange}
                      value={formik.values.postalZip}
                      data-test-id="zip-code-field"
                    />
                  </span>
                  <span>
                    <Input
                      name="country"
                      placeholder="Country"
                      style={{
                        width: '200px',
                      }}
                      onChange={formik.handleChange}
                      value={formik.values.country}
                      data-test-id="country-address-field"
                    />
                  </span>
                </li>
                <li>
                  <span style={styles.formSpan}>
                    <Trans i18nKey="payment.finfluencerEngagementLetter.email" />
                  </span>
                  <span>
                    <Input
                      name="email"
                      placeholder="Email"
                      style={{
                        width: '200px',
                        marginLeft: '1rem',
                      }}
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      data-test-id="email-field"
                    />
                    <Text style={styles.text}>
                      <p>{pdf && formik.values.email}</p>
                    </Text>
                  </span>
                </li>
                <Text style={styles.text}>
                  <li>
                    <span style={styles.formSpanBold}>
                      <Trans i18nKey="payment.finfluencerEngagementLetter.attention" />
                    </span>
                    <span>
                      <Input
                        name="name"
                        placeholder="Full Name"
                        style={{
                          width: '200px',
                          marginLeft: '1rem',
                        }}
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        data-test-id="full-name-field"
                      />
                      <Text style={styles.text}>
                        <p>{pdf && formik.values.name}</p>
                      </Text>
                    </span>
                  </li>
                </Text>
                <Text style={styles.text}>
                  <li>
                    <span style={styles.formSpan}>
                      <Trans i18nKey="payment.finfluencerEngagementLetter.dear" />
                    </span>
                    <span>
                      <Input
                        name="name"
                        placeholder="Full Name"
                        style={{
                          width: '200px',
                          marginLeft: '1rem',
                        }}
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        data-test-id="full-name-field"
                      />
                      <Text style={styles.text}>
                        <p>{pdf && formik.values.name}</p>
                      </Text>
                    </span>{' '}
                  </li>
                </Text>
              </>
            ) : (
              <>
                <Text style={styles.textForwarded}>
                  <p>{formik.values.streetAddress}</p>
                </Text>
                <Text style={styles.textForwarded}>
                  {formik.values.city}, {formik.values.provinceState},{' '}
                  {formik.values.postalZip},
                </Text>
                <Text style={styles.textForwarded}>
                  {formik.values.country}
                </Text>
                <Text style={styles.textForwarded}>
                  <li>
                    <div style={styles.form}>
                      <span style={styles.formSpan}>
                        <Trans i18nKey="payment.finfluencerEngagementLetter.email" />{' '}
                      </span>
                      <span>
                        <p>{pdf && formik.values.email}</p>
                      </span>
                    </div>
                  </li>
                </Text>
                <Text style={styles.textForwarded}>
                  <li>
                    <div style={styles.form}>
                      <span style={styles.formSpan}>
                        <Trans i18nKey="payment.finfluencerEngagementLetter.attention" />{' '}
                      </span>
                      <span>
                        <p>{pdf && formik.values.name}</p>
                      </span>
                    </div>
                  </li>
                </Text>
                <Text style={styles.textForwarded}>
                  <li>
                    <div style={styles.form}>
                      <span style={styles.formSpan}>
                        <Trans i18nKey="payment.finfluencerEngagementLetter.name" />{' '}
                      </span>
                      <span>
                        <p>{pdf && formik.values.name}</p>
                      </span>
                    </div>
                  </li>
                </Text>
                <Text style={styles.textForwarded}></Text>
              </>
            )}
            <Text style={pdf ? styles.text : styles.bold}>
              <span style={{ display: 'inline-flex' }}>
                <Trans i18nKey="payment.finfluencerEngagementLetter.RE" />
                <div style={styles.re}>
                  <Trans i18nKey="payment.finfluencerEngagementLetter.RE1" />{' '}
                  {formik.values.name}{' '}
                  <Trans i18nKey="payment.finfluencerEngagementLetter.RE2" />
                  <span> {getCurrentDate()} </span>
                  <Trans i18nKey="payment.finfluencerEngagementLetter.RE3" />
                  <HorizontalDivider
                    style={{ width: 'auto', borderTop: '1px solid' }}
                  />
                </div>
              </span>
            </Text>
            <Text style={styles.title}>
              <p>
                <Trans i18nKey="payment.finfluencerEngagementLetter.background" />
              </p>
            </Text>
            <Text style={styles.text}>
              <p>
                <Trans i18nKey="payment.finfluencerEngagementLetter.background1" />
              </p>
            </Text>
            <Text style={styles.text}>
              <p>
                <Trans i18nKey="payment.finfluencerEngagementLetter.background2" />
              </p>
            </Text>
            <Text style={styles.text}>
              <p>
                <Trans i18nKey="payment.finfluencerEngagementLetter.background3" />
              </p>
            </Text>
            <OL>
              <Text style={styles.text}>
                <li>
                  <Trans i18nKey="payment.finfluencerEngagementLetter.background31" />
                </li>
              </Text>
              <Text style={styles.text}>
                <li>
                  <Trans i18nKey="payment.finfluencerEngagementLetter.background32" />
                  <a href="https://www.brandpilot.ai/influencersstandardterms ">
                    here
                  </a>
                  .
                </li>
              </Text>
            </OL>
            <Text style={styles.title}>
              <p>
                <Trans i18nKey="payment.finfluencerEngagementLetter.engagementServises" />
              </p>
            </Text>
            <Text style={styles.text}>
              <p>
                <Trans i18nKey="payment.finfluencerEngagementLetter.engagementServises1" />
              </p>
            </Text>
            <Text style={styles.text}>
              <p>
                <Trans i18nKey="payment.finfluencerEngagementLetter.engagementServises2" />
              </p>
            </Text>
            <Text style={styles.title}>
              <p>
                <Trans i18nKey="payment.finfluencerEngagementLetter.fees" />
              </p>
            </Text>
            <Text style={styles.text}>
              <p>
                <Trans i18nKey="payment.finfluencerEngagementLetter.fees1" />
              </p>
            </Text>
            <Text style={styles.title}>
              <p>
                <Trans i18nKey="payment.finfluencerEngagementLetter.term" />
              </p>
            </Text>
            <Text style={styles.text}>
              <p>
                <Trans i18nKey="payment.finfluencerEngagementLetter.term1" />
              </p>
            </Text>
            <Text style={styles.title}>
              <p>
                <Trans i18nKey="payment.finfluencerEngagementLetter.termination" />
              </p>
            </Text>
            <Text style={styles.text}>
              <p>
                <Trans i18nKey="payment.finfluencerEngagementLetter.termination1" />
              </p>
            </Text>
            <OL>
              <Text style={styles.text}>
                <li>
                  <Trans i18nKey="payment.finfluencerEngagementLetter.termination11" />
                </li>
              </Text>
              <Text style={styles.text}>
                <li>
                  <Trans i18nKey="payment.finfluencerEngagementLetter.termination12" />
                </li>
              </Text>
              <Text style={styles.text}>
                <li>
                  <Trans i18nKey="payment.finfluencerEngagementLetter.termination13" />
                </li>
              </Text>
              <Text style={styles.text}>
                <li>
                  <Trans i18nKey="payment.finfluencerEngagementLetter.termination14" />
                </li>
              </Text>
            </OL>
            <Text style={styles.title}>
              <p>
                <Trans i18nKey="payment.finfluencerEngagementLetter.acceptanceTerms" />
              </p>
            </Text>
            <Text style={styles.text}>
              <p>
                <Trans i18nKey="payment.finfluencerEngagementLetter.acceptanceTerms1" />
              </p>
            </Text>
            <Text style={styles.text}>
              <p>
                <Trans i18nKey="payment.finfluencerEngagementLetter.acceptanceTerms2" />
              </p>
            </Text>
            <Text style={styles.text}>
              <p>
                <Trans i18nKey="payment.finfluencerEngagementLetter.regards" />
              </p>
            </Text>
          </OL>
          <UL>
            <OL>
              <div>
                <Text style={styles.bold}>
                  <br />
                  <p>
                    <Trans i18nKey="payment.finfluencerEngagementLetter.xemoto" />
                  </p>
                </Text>
                <div>
                  <div>
                    <Text style={styles.text}>
                      <Trans i18nKey="payment.finfluencerEngagementLetter.per" />
                      <br />
                      <br />
                    </Text>
                    <div>
                      <Image src={signByCeo} style={styles.image} />
                      {pdf ? (
                        <Text style={styles.text}>
                          ________________________________________
                        </Text>
                      ) : (
                        <>
                          <img src={signByCeo} alt="signByCeo" width="150px" />
                          <HorizontalDivider
                            style={{ width: '200px', borderTop: '1px solid' }}
                          />
                        </>
                      )}
                      <Text style={styles.text2}>
                        <Trans i18nKey="payment.finfluencerEngagementLetter.ceo" />
                      </Text>
                      <br />
                      <br />
                      <Text style={styles.text2}>
                        <Trans i18nKey="payment.finfluencerEngagementLetter.agreed" />
                        <span>{getCurrentDate()}</span>
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            </OL>
            <OL>
              <div>
                <Text style={styles.text}>
                  <Trans i18nKey="payment.finfluencerEngagementLetter.per" />
                  <br />
                  <br />
                </Text>
                <Input
                  name="company"
                  placeholder="Company/Full Name"
                  style={{
                    width: '200px',
                  }}
                  onChange={formik.handleChange}
                  value={formik.values.company}
                  data-test-id="summary-company-name-field"
                />
                <Text style={styles.text}>
                  <p>{pdf && formik.values.company}</p>
                </Text>
                <br />
                <div>
                  <div>
                    <div>
                      {!formik.values.signUrl ? (
                        <StyledPopup
                          trigger={
                            <div
                              style={styles.signBtn}
                              data-test-id="sign-field"
                            >
                              Sign
                              <br />
                              <GetAppIcon />
                            </div>
                          }
                          modal
                          closeOnDocumentClick={false}
                        >
                          {(close: any) => (
                            <SignatureWrap>
                              <div>
                                <SignaturePad ref={sigCanvas} />
                              </div>
                              <ButtonWrap>
                                <PlxButtonX
                                  onClick={save}
                                  label="Sign"
                                  size="small"
                                  testId="sign-button"
                                ></PlxButtonX>
                                <PlxButtonX
                                  onClick={clear}
                                  label="Clear"
                                  size="small"
                                  testId="clear-button"
                                ></PlxButtonX>
                                <PlxButtonX
                                  onClick={close}
                                  label="Close"
                                  size="small"
                                  testId="close-button"
                                ></PlxButtonX>
                              </ButtonWrap>
                            </SignatureWrap>
                          )}
                        </StyledPopup>
                      ) : (
                        <>
                          {pdf ? (
                            <>
                              <Image
                                src={formik.values.signUrl}
                                style={styles.image}
                              />
                              <Text style={styles.text}>
                                ________________________________________
                              </Text>
                            </>
                          ) : (
                            <img
                              data-test-id="document-signed-img"
                              src={formik.values.signUrl}
                              alt="sign-img"
                              style={{
                                display: 'block',
                                maxWidth: '150px',
                                maxHeight: '150px',
                              }}
                            />
                          )}
                        </>
                      )}
                      <Text style={styles.text}>
                        <IdentifierWrap>
                          <HorizontalDivider
                            style={{ width: '50px', borderTop: '1px solid' }}
                          />
                          <span>{formik.values.identifier}</span>
                          <HorizontalDivider
                            style={{ width: '100px', borderTop: '1px solid' }}
                          />
                        </IdentifierWrap>
                      </Text>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </OL>
          </UL>
        </BodyWrap>
      </Page>
      <ErrorWrap>
        {formik.errors.company ||
          formik.errors.streetAddress ||
          formik.errors.city ||
          formik.errors.provinceState ||
          formik.errors.postalZip ||
          formik.errors.country ||
          formik.errors.name ||
          formik.errors.email ||
          formik.errors.signUrl}
      </ErrorWrap>
    </Document>
  );
};
