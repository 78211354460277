import styled from '@emotion/styled';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';

export const CurrentBalance = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  padding: 3rem 4rem;
  align-items: center;
  background-color: #f8f9fb;
  border-radius: 5px;
  line-height: 2.2rem;
`;
export const PMHeader = styled.div`
  display: flex;
  margin-top: 4rem;
  justify-content: center;
  align-items: center;
  a {
    text-decoration: none;
  }
`;
export const PaymentMethods = styled.div`
  margin-bottom: 2rem;
`;
export const AddPaymentMethods = styled(PlxButtonX)`
  && span {
    color: #a9b3bb;
    font-weight: 600;
  }
`;
export const PaymentDetails = styled.div<{ defaultWallet: boolean }>`
  min-width: 200px;
  width: 100%;
  max-width: 250px;
  height: 15rem;
  border-radius: 20px;
  padding: 1.3rem 2rem;
  font-weight: 700;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: ${({ defaultWallet }) => (defaultWallet ? '#CA85D7' : '#f5f6f7')};
  font-size: 1.2rem;
`;
export const PaymentDetailsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`;
export const CardDetailsContainer = styled.div``;
export const CardHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const AccNumber = styled.div`
  word-break: break-all;
  line-height: 2rem;
`;
export const WalletLabel = styled.div`
  margin-bottom: 1rem;
`;
export const OptionsContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  font-size: 2.5rem;
  color: #fff;
  text-align: center;
  border-radius: 20px;
  z-index: 0;
  opacity: 0;
  transition: opacity 0.6s;
  :hover {
    opacity: 0.8;
  }

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

export const CardOptions = styled.div`
  border: 1px solid #fff;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #545454;

  :hover {
    background-color: #000;
  }
`;
export const SectionWrap = styled.div`
  width: 100%;
  margin-bottom: 3rem;
`;

export const AddPaymentMethodsWrap = styled.div`
  display: flex;
  gap: 2rem;
  border: 1px solid #e5e5e5;
  border-radius: 50px;
  padding-left: 2rem;
  span {
    display: flex;
    align-items: center;
  }
`;
export const Title = styled.div`
  font-family: Rubik;
  font-weight: 500;
  font-size: 2.1rem;
  line-height: 4.1rem;
  text-align: center;
  color: #1b1b1b;
`;
export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  gap: 1rem;
`;
export const StyledButton = styled(PlxButtonX)`
  width: 11rem;
`;

export const WarningMessage = styled.div`
  font-size: 1rem;
  color: gray;
`;

export const WalletButton = styled.input`
  background: none;
  border: 1px solid #000;
  border-radius: 10px;
  margin: 1rem 0.5rem;
  cursor: pointer;
`;
export const XemTextField = styled.input`
  border-radius: 15px;
  padding: 0.3rem 1rem;
`;
export const DialogWrapper = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-size: 2rem;
  line-height: 2.8rem;
`;
export const ErrHeader = styled.div`
  max-width: 500px;
  text-align: center;
  margin: auto;
`;
export const ErrNote = styled.div`
  font-size: 1.2rem;
  line-height: 2rem;
  a {
    text-decoration: none;
  }
`;
